.video-side-bar {
    font-size: 16px;
    height: 76vh;
    overflow: auto;

    &.active {
        display: block;
        width: 100%;
    }

    .row {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 100%;
            max-width: 120px;
            margin-right: 20px;
        }
        .description {
            word-break: break-all;
        }
    }
}

.desc-cont {
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: pointer;
    & p {
        margin-left: 7px;
    }
}

.option-icon {
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.participant-avatar {
    border-radius: 50%;
    border-width: 5px;
    border-color: transparent;
    border-style: solid;
    &.active {
        border-color: #6264a7;
    }

    &.empty {
        border-color: #e40202;
    }
}

@media (min-width: 0px) and (max-width: 1280px) {
    .video-side-bar {
        display: none;
    }
}
